import request from '../utils/request'
import config from './config'

/**
 * 积分体系
 */

// 新增积分体系
export function addIntegralSystem(data) {
	return request({
		url: `${config.CIVIL_HOST}/integralSystem/addIntegralSystem`,
		method: 'post',
		data,
	})
}

// 查询积分列表
export function getIntegralSystemList(data) {
	return request({
		url: `${config.CIVIL_HOST}/integralSystem/getIntegralSystemList`,
		method: 'post',
		data,
	})
}

// 编辑积分体系（包含启用停用）
export function updateIntegralSystem(data) {
	return request({
		url: `${config.CIVIL_HOST}/integralSystem/updateIntegralSystem`,
		method: 'post',
		data,
	})
}

/**
 * 积分汇总
 */

// 获取当前单位下的积分体系
export function getIntegralRecordList(data) {
	return request({
		url: `${config.CIVIL_HOST}/integralSummary/getIntegralRecordList`,
		method: 'post',
		data,
	})
}

// 积分记录列表
export function getIntegralSummaryList(data) {
	return request({
		url: `${config.CIVIL_HOST}/integralSummary/getIntegralSummaryList`,
		method: 'post',
		data,
	})
}